.post-titles {
  font-weight: bold;
}

.post-tile {
  padding-top: 20px;
  padding-bottom: 20px;
  border: 1px solid #ececec;
  margin-top: 10px;
}

.post-titles > div {
  padding: 5px;
}

.post-titles > div.action:hover {
  background-color: #ececec;
  cursor: pointer;
}


.post-tile > div:last-child {
  text-align: right;
}

.post-tile > div:last-child > button {
  margin-right: 10px;
}
