.auth-form__logo {
  max-width: 100% !important;
}

.Login {
  margin-top: 200px;
}

.Login .auth-form .card-body {
  box-shadow: inset 0 4px 0 0 #53be83 !important;
}

.Login .auth-form {
  max-width: 400px;
}

.Login svg {
  padding-bottom: 2px;
  margin-right: 10px;
}
