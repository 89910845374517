.image_preview {
  width: 185px;
  margin-top: 28px;
  min-height: 100px;
  text-align: center;
}


@media only screen and (max-width: 600px) {
  .image_preview {
    display: none;
  }
}

.react-loader-container-main {
  border-color: #0c673b !important;
  animation: none;
}


.react-datetime-picker__wrapper {
  border: 1px solid #e1e5eb !important;
  border-radius: 5px;
  padding: 5px 15px 5px 15px;
}
