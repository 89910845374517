.item-icon-wrapper svg {
  margin-right: 10px;
}

.sign-out-container {
  padding: 16px;
}


.nav-item > .nav-link.active {
  box-shadow: inset 0.1875rem 0 0 #53be83 !important;
  color: #53be83 !important;
}


.nav-item > .nav-link:hover {
  box-shadow: inset 0.1875rem 0 0 #53be83 !important;
  color: #53be83 !important;
}
